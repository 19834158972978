import { ExtraConfig, HttpResult } from '@bob/api-client'
import * as api from '@bob/api-client/latest'

import { getCookie } from '@bob/cookie-helper'
import { Either } from '@bob/monad'

//FIXME: move this out of the `account` service
export async function variant<EXTRA_FIELDS extends string>(
    variantId: number,
    extraFields: EXTRA_FIELDS[],
    options: ExtraConfig = {}
): Promise<HttpResult<api.Variant<EXTRA_FIELDS>>> {
    const rewardResponse = await api.api.get.variant({
        urlParams: {
            variantId: String(variantId)
        },
        getParams: {
            extra_fields: extraFields.join(',')
        },
        setCookie: options.setCookie,
        getCookie: options.getCookie,
        refreshAccessToken: options.refreshAccessToken
    })

    return rewardResponse.next(response => {
        return response.body
    })
}

export async function guest(
    email: string,
    shopifyOrderNumber: number,
    options: ExtraConfig = {}
): Promise<HttpResult<api.AuthenticatedUser>> {
    await api.api.oauth2(
        'shopify_order_number',
        {
            email,
            shopify_order_number: shopifyOrderNumber
        },
        {
            setCookie: options.setCookie,
            getCookie: options.getCookie,
            removeCookie: options.removeCookie
        }
    )

    return me({
        setCookie: options.setCookie,
        getCookie: options.getCookie,
        removeCookie: options.removeCookie,
        refreshAccessToken: options.refreshAccessToken
    })
}

export async function me(
    options: ExtraConfig = {}
): Promise<HttpResult<api.AuthenticatedUser>> {
    const userResponse = await api.api.get.me(options)

    return userResponse.next(response => response.body)
}

export async function meOrGuest(
    email?: string,
    shopifyOrderNumber?: number,
    options: ExtraConfig = {}
): Promise<HttpResult<api.AuthenticatedUser>> {
    const accessToken = (options?.getCookie ?? getCookie)(
        process.env.NEXT_PUBLIC_ACCESS_TOKEN_COOKIE_NAME ?? ''
    )
    const refreshToken = (options?.getCookie ?? getCookie)(
        process.env.NEXT_PUBLIC_REFRESH_TOKEN_COOKIE_NAME ?? ''
    )

    if (email !== undefined && shopifyOrderNumber !== undefined) {
        return await guest(email, shopifyOrderNumber, options)
    }

    if (accessToken === undefined && refreshToken === undefined) {
        return Either.left({
            status: 401,
            url: '/me',
            body: undefined
        })
    }

    return me(options)
}
