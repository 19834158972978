import styled from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import * as copy from './copy'
import * as icons from '../svg/icons'

export type BackLinkProps = {
    hideOnBigScreen?: boolean
}

export const Wrapper = styled.div<BackLinkProps>`
    display: inline-block;
    margin-bottom: 30px;
    cursor: pointer;

    ${icons.ChevronLeft} {
        margin-right: 28px;
        margin-bottom: 6px;
    }

    @media screen and (${breakpoint.TABLET}) {
        display: ${props => (props.hideOnBigScreen ? 'none' : 'inline-block')};
    }
`

export const LinkText = styled(copy.Text5).attrs(props => ({
    as: 'span',
    ...props
}))``
