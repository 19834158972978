import styled, { css, keyframes } from 'styled-components'

import * as font from '../constants/fonts'
import * as breakpoint from '../constants/breakpoints'
import * as heading from './heading'

const offset = '20vw'
const moveInitial = `translate3d(calc(-25% + ${offset}), 0, 0)`
const moveFinal = `translate3d(calc(-50% + ${offset}), 0, 0)`

export const Ribbon = styled.div`
    position: relative;
    overflow: hidden;
    transform: skew(0deg, -4deg);
    margin: 50px 0;
    background: #ffeba4; // TODO: FIXME: Add dedicated color

    @media screen and (${breakpoint.TABLET}) {
        margin-top: 60px;
        margin-bottom: 80px;
    }
`

const Marquee = keyframes`
    0% {
        transform: ${moveInitial};
    }
    100% {
        transform: ${moveFinal};
    }
`

export type InnerProps = { timing?: number; reverse?: boolean }

export const Inner = styled.div<InnerProps>`
    width: fit-content;
    display: flex;
    position: relative;
    transform: ${moveInitial};
    animation: ${Marquee} ${props => (props.timing ? props.timing : 20)}s linear infinite;

    ${({ reverse }) =>
        reverse &&
        css`
            animation-direction: reverse;
        `}
`

export const Word = styled(heading.Title3)`
    display: inline-block;
    font-family: ${font.PRIMARY};
    line-height: 1;
    padding: 0.7em 0;
    text-align: center;
    white-space: nowrap;
    user-select: none;
`
