import * as React from 'react'
import styled from 'styled-components'

import * as S from '../../../styles'

export type FieldStatusProps = {
    className?: string
    error?: React.ReactNode
    success?: React.ReactNode
    children?: React.ReactNode
}

function FieldStatusComponent({
    className,
    error,
    success,
    children
}: FieldStatusProps): React.ReactElement<FieldStatusProps> {
    return (
        <S.form.field.Status className={className}>
            {error && <S.form.field.Message type="error">{error}</S.form.field.Message>}
            {success && (
                <S.form.field.Message type="success">{success}</S.form.field.Message>
            )}
            {children}
        </S.form.field.Status>
    )
}

export const FieldStatus = styled(FieldStatusComponent)``
