import styled, { css } from 'styled-components'

import * as color from '../../constants/colors'
import * as copy from '../copy'

/* Field */
export const Wrapper = styled.div`
    position: relative;
`
export const ActualLabel = styled.label``
export const TitleWrapper = styled.div`
    margin-bottom: 4px;
    display: inline-block;
`
export const Title = styled(copy.Text1)``
export const Context = styled.div``
export const Description = styled.div`
    margin-bottom: 8px;
`

/* FieldStatus */
export const Status = styled.div`
    position: absolute;
    top: 103%;
    right: 0;
    text-align: right;
    width: 100%;
`
export const Message = styled(copy.TextInfo)<{ type: 'error' | 'success' }>`
    ${({ type }) => {
        if (type === 'error') {
            return css`
                color: ${color.ERROR};
            `
        }
        if (type === 'success') {
            return css`
                color: ${color.LIGHT_GREEN};
            `
        }
    }}
`
