var __jsx = React.createElement;
import * as React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import * as ds from '@bob/design-system';
import { LoginForm } from './LoginForm';
import { useToast } from '@bob/toasts';
export function Login() {
  var router = useRouter();
  var toast = useToast();
  var failureCode = router.query.failure_code;
  React.useEffect(function () {
    if (failureCode && failureCode == 'invalid_order') {
      toast.dispatch({
        id: Date.now(),
        status: 'error',
        children: 'Impossible de récupérer votre commande, vérifiez vos identifiants.'
      });
    }
  }, [failureCode]);
  return __jsx(React.Fragment, null, __jsx(Wrapper, null, __jsx(ds.BackLink, {
    onClick: function onClick() {
      return router.back();
    }
  }, "Connexion"), __jsx(LoginForm, null)), __jsx(ds.Ribbon, {
    cycles: 12,
    title: "connectez-vous"
  }));
}
var Wrapper = styled.div.withConfig({
  displayName: "view__Wrapper",
  componentId: "sc-1boe1uy-0"
})(["padding:0px 20px;margin:0 auto;max-width:375px;display:flex;flex-direction:column;", "{align-self:flex-start;cursor:pointer;}", "{position:relative;}", "{position:absolute;top:101%;right:0;}"], ds.BackLink, ds.styles.form.input.InputWrapper, ds.styles.copy.TextInfo);