import * as React from 'react'
import styled from 'styled-components'

import * as S from '../../../styles'

import { Input, InputProps } from '../Input'
import { Field } from './Field'
import { FieldStatus } from './FieldStatus'

export type TextFieldProps = InputProps & {
    label?: React.ReactNode
    description?: string
    error?: React.ReactNode
    success?: React.ReactNode
}

function TextFieldComponent({
    className,
    label,
    description,
    error,
    success,
    children,
    id = String(Math.random()),
    ...inputProps
}: TextFieldProps): React.ReactElement<TextFieldProps> {
    let status: 'default' | 'error' | 'success' = 'default'
    if (error && !success) {
        status = 'error'
    }
    if (success && !error) {
        status = 'success'
    }

    const [length, setLength] = React.useState(0)

    const maxLength = inputProps.maxLength

    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const onChange = inputProps.onChange
        onChange && onChange(event)
        if (maxLength !== undefined) {
            setLength(event.target.value.length)
        }
    }

    return (
        <TextFieldWrapper
            className={className}
            htmlFor={id}
            label={label}
            description={description}
        >
            {maxLength !== undefined && (
                <S.form.input.Count isError={length > maxLength}>
                    {length}/{maxLength}
                </S.form.input.Count>
            )}
            {children}
            <Input
                {...inputProps}
                type={
                    inputProps.type as
                        | 'text'
                        | 'search'
                        | 'email'
                        | 'password'
                        | undefined
                }
                onChange={handleChange}
                status={status}
                id={id}
            />
            {(error || success || maxLength !== undefined) && (
                <FieldStatus error={error} success={success} />
            )}
        </TextFieldWrapper>
    )
}

export const TextField = styled(TextFieldComponent)``

export const TextFieldWrapper = styled(Field)`
    ${S.form.input.Count} {
        position: absolute;
        top: 10px;
        right: 0.5%;
        white-space: nowrap;
    }
`
