import styled from 'styled-components'

import * as S from '../styles'

export type RibbonProps = React.HTMLAttributes<HTMLDivElement> &
    Omit<S.ribbon.InnerProps, 'idle'> & {
        cycles: number
        title: string
    }

function RibbonComponent({
    cycles,
    timing,
    title,
    reverse,
    ...rest
}: RibbonProps): React.ReactElement {
    return (
        <S.ribbon.Ribbon {...rest}>
            <S.ribbon.Inner timing={timing} reverse={reverse} aria-hidden="true">
                {[...Array(cycles)].map((el, index) => (
                    <S.ribbon.Word key={index}>&nbsp;{title}&nbsp;-</S.ribbon.Word>
                ))}
            </S.ribbon.Inner>
        </S.ribbon.Ribbon>
    )
}

export const Ribbon = styled(RibbonComponent)``
