import * as React from 'react'
import styled from 'styled-components'

import * as S from '../../../styles'

export type FieldProps = React.HTMLAttributes<HTMLDivElement> & {
    className?: string
    children?: React.ReactNode
    htmlFor?: string
    label?: React.ReactNode
    context?: string
    description?: React.ReactNode
}

function FieldComponent({
    label,
    context,
    htmlFor,
    description,
    children,
    className,
    ...props
}: FieldProps): React.ReactElement<FieldProps> {
    return (
        <S.form.field.Wrapper className={className} {...props}>
            <S.form.field.ActualLabel htmlFor={htmlFor}>
                {label !== undefined && (
                    <S.form.field.TitleWrapper>
                        <S.form.field.Title>{label}</S.form.field.Title>
                        {context !== undefined && (
                            <S.form.field.Context>{context}</S.form.field.Context>
                        )}
                    </S.form.field.TitleWrapper>
                )}
            </S.form.field.ActualLabel>
            {description && (
                <S.form.field.Description>{description}</S.form.field.Description>
            )}
            {children}
        </S.form.field.Wrapper>
    )
}

export const Field = styled(FieldComponent)``
