import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'
import * as icons from '../svg/icons'

export type BackLinkProps = {
    hideOnBigScreen?: boolean
} & React.HTMLAttributes<HTMLDivElement>

function BackLinkComponent({
    children,
    hideOnBigScreen,
    ...rest
}: BackLinkProps): React.ReactElement {
    return (
        <S.backLink.Wrapper hideOnBigScreen={hideOnBigScreen} {...rest}>
            <icons.ChevronLeft />
            <S.backLink.LinkText>{children}</S.backLink.LinkText>
        </S.backLink.Wrapper>
    )
}

export const BackLink = styled(BackLinkComponent)``
